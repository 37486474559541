import React from 'react';
import { CodethroughTextItem } from '../../../types';

interface ViewCodethroughTextItemRowContentProps {
  item: CodethroughTextItem;
}

const ViewCodethroughTextItemRowContent: React.FC<ViewCodethroughTextItemRowContentProps> = ({
  item,
}) => {
  return (
    <div className='text-white'>
      <p>{item.content}</p>
    </div>
  );
};

export default ViewCodethroughTextItemRowContent;
