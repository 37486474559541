import React from 'react';

interface PrimaryButtonProps {
  onClick?(): void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ onClick, children }) => {
  return (
    <button
      className='flex flex-row bg-primary border-white hover:border-black focus:outline-none text-black px-5 py-2 rounded'
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
