import SubjectIcon from '@material-ui/icons/Subject';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import {
  CodethroughFileItem,
  CodethroughFolderItem,
  CodethroughItem,
  CodethroughItemType,
  CodethroughTextItem,
  EnumMap,
} from '../types';

export const getIconForItemType = (
  type: CodethroughItemType,
): React.ReactElement => {
  const map: EnumMap<CodethroughItemType, React.ReactElement> = {
    FILE: <DescriptionIcon />,
    FOLDER: <FolderOpenIcon />,
    TEXT: <SubjectIcon />,
  };

  return map[type];
};

export const isTextCodethroughItem = (
  item: CodethroughItem,
): item is CodethroughTextItem => {
  return item.type === CodethroughItemType.TEXT;
};

export const isFolderCodethroughItem = (
  item: CodethroughItem,
): item is CodethroughFolderItem => {
  return item.type === CodethroughItemType.FOLDER;
};

export const isFileCodethroughItem = (
  item: CodethroughItem,
): item is CodethroughFileItem => {
  return item.type === CodethroughItemType.FILE;
};
