import React from 'react';
import { CodethroughFolderItem } from '../../../types';
import FolderContent from '../folder/FolderContent';
import ViewCodethroughTextItemRowContent from './ViewCodethroughTextItemRowContent';

interface ViewCodethroughFolderItemRowContentsProps {
  item: CodethroughFolderItem;
}

const ViewCodethroughFolderItemRowContents: React.FC<ViewCodethroughFolderItemRowContentsProps> = ({
  item,
}) => {
  return (
    <div>
      <div className='mb-5'>
        <ViewCodethroughTextItemRowContent item={item} />
      </div>

      <FolderContent
        path={item.path.split('/')}
        contents={item.folderContents}
      />
    </div>
  );
};

export default ViewCodethroughFolderItemRowContents;
