import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import store from './slices/store';
import Sidebar from './components/Sidebar';
import EditCodethroughContainer from './containers/EditCodethroughContainer';
import ViewCodethroughContainer from './containers/ViewCodethroughContainer';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className='grid grid-cols-6'>
          <div className='col-span-1 col-start-2'>
            <Sidebar />
          </div>

          <div className='col-span-3'>
            <Route path='/view' component={ViewCodethroughContainer} exact />
            <Route path='/' component={EditCodethroughContainer} exact />
          </div>
        </div>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
