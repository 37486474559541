interface IConfig {
  github: {
    accessToken: string;
  };
}

const config: IConfig = {
  github: { accessToken: process.env.REACT_APP_GITHUB_ACCESS_TOKEN! },
};

export default config;
