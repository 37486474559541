import React from 'react';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import classNames from 'classnames';
import { FolderContents } from '../../../types';

interface FileRowProps {
  file: FolderContents;
  onClick?(name: string): void;
}

const FileRow: React.FC<FileRowProps> = ({ file, onClick }) => {
  return (
    <button
      onClick={() => {
        onClick && onClick(file.name);
      }}
      className={classNames('flex flex-row py-1', {
        'hover:bg-gray-300 hover:text-black cursor-pointer': !!onClick,
        'cursor-default': !onClick,
      })}
    >
      <div className='ml-2 mr-5'>
        <InsertDriveFileIcon />
      </div>
      <p>{file.name}</p>
    </button>
  );
};

export default FileRow;
