import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import ActionBar from '../components/ActionBar';
import { RootState } from '../types';
import EditCodethroughItemRow from '../components/EditCodethroughItemRow';
import ContentEditableParagraph from '../components/ContentEditableParagraph';
import { setTitle } from '../slices/CodethroughSlice';
import PrimaryButton from '../components/PrimaryButton';

interface CodethroughContainerProps extends RouteComponentProps {}

const EditCodethroughContainer: React.FC<CodethroughContainerProps> = ({
  history,
}) => {
  const dispatch = useDispatch();

  const { codethrough } = useSelector((state: RootState) => state);
  const { title, items } = codethrough;

  return (
    <div className='container mx-auto py-5'>
      <div className='flex flex-row justify-between text-white text-4xl my-5'>
        <ContentEditableParagraph
          content={title}
          setContent={(content) => dispatch(setTitle(content))}
        />
        <div className='text-lg'>
          <PrimaryButton onClick={() => history.push('/view')}>
            View
          </PrimaryButton>
        </div>
      </div>

      <ActionBar />

      <div className='flex flex-col space-y-3 mt-5'>
        {items.map((item) => (
          <EditCodethroughItemRow key={item.id} item={item} />
        ))}
      </div>

      <div className='mt-5'>
        <p className='text-white'>JSON:</p>
        <pre className='text-white'>{JSON.stringify(codethrough, null, 2)}</pre>
      </div>
    </div>
  );
};

export default EditCodethroughContainer;
