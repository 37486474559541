import React from 'react';

interface SidebarProps {}

const Sidebar: React.FC<SidebarProps> = () => {
  return (
    <div className='m-5 p-5 bg-gray-900 rounded'>
      <div className='flex flex-row items-center'>
        <img
          className='w-1/6 mr-2'
          src='https://www.freepnglogos.com/uploads/spotify-logo-png/file-spotify-logo-png-4.png'
          alt='spotify logo'
        />
        <h5 className='text-white text-lg'>Spotify Engineering</h5>
      </div>
    </div>
  );
};

export default Sidebar;
