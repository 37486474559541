import { Action } from 'redux';
import { ThunkAction } from '@reduxjs/toolkit';
import store from './slices/store';

export type EnumMap<E extends string, T> = {
  [key in E]: T;
};

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export interface RootState {
  codethrough: CodethroughSliceState;
}

export interface CodethroughSliceState {
  title: string;
  items: CodethroughItem[];
}

export interface ReduxStateFactory<T> {
  getState(state: Partial<T>): T;
}

export interface CodethroughItem {
  id: string;
  type: CodethroughItemType;
}

export interface CodethroughTextItem extends CodethroughItem {
  content: string;
}

export interface CodethroughFolderItem extends CodethroughTextItem {
  path: string;
  folderContents: FolderContents[];
}

export interface FolderContents {
  name: string;
  type: 'dir' | 'file';
}

export interface CodethroughFileItem extends CodethroughTextItem {
  path: string;
  fileName: string;
  fileContents: string;
}

export enum CodethroughItemType {
  TEXT = 'TEXT',
  FILE = 'FILE',
  FOLDER = 'FOLDER',
}

export interface FileInfo {
  name: string;
  path: string;
  contents: string;
}
