import React, { useState } from 'react';

interface ContentEditableParagraphProps {
  content: string;
  setContent(content: string): void;
}

const ContentEditableParagraph: React.FC<ContentEditableParagraphProps> = ({
  content,
  setContent,
}) => {
  const [data, _setData] = useState<string>(content);

  return (
    <p
      onInput={(event) => {
        setContent(event.currentTarget.innerText);
      }}
      contentEditable='true'
    >
      {data}
    </p>
  );
};

export default ContentEditableParagraph;
