import React from 'react';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import PrimaryButton from '../../PrimaryButton';
import { FileInfo } from '../../../types';

interface FileContentProps {
  fileInfo: FileInfo;
  onSave?(): void;
  onClear?(): void;
}

const FileContent: React.FC<FileContentProps> = ({
  fileInfo,
  onSave,
  onClear,
}) => {
  return (
    <div className='flex flex-col bg-warmGray-500 rounded-t'>
      <div className='flex flex-row justify-between items-center px-5 py-2'>
        <div className='flex flex-row text-lg text-white'>
          <FolderOpenIcon />
          <p className='ml-5'>
            /{fileInfo.path}/{fileInfo.name}
          </p>
        </div>
        {onSave && onClear && (
          <div className='flex flex-row space-x-2'>
            <PrimaryButton onClick={onClear}>Go Back</PrimaryButton>

            <PrimaryButton onClick={onSave}>Save</PrimaryButton>
          </div>
        )}
      </div>

      <div className='bg-warmGray-800 text-white'>
        <div className='p-5'>
          <pre>{fileInfo.contents}</pre>
        </div>
      </div>
    </div>
  );
};

export default FileContent;
