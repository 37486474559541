import { Octokit } from 'octokit';
import React, { useCallback, useEffect, useState } from 'react';
import { RestEndpointMethodTypes } from '@octokit/plugin-rest-endpoint-methods/dist-types/generated/parameters-and-response-types';
import { useDispatch } from 'react-redux';
import { CodethroughFolderItem, FolderContents } from '../../../types';
import PrimaryButton from '../../PrimaryButton';
import config from '../../../config';
import { updateItem } from '../../../slices/CodethroughSlice';
import FolderContent from '../folder/FolderContent';
import EditCodethroughTextItemRowContent from './EditCodethroughTextItemRowContent';

interface CodethroughFolderItemProps {
  item: CodethroughFolderItem;
}

const EditCodethroughFolderItemRowContent: React.FC<CodethroughFolderItemProps> = ({
  item,
}) => {
  const dispatch = useDispatch();
  const [path, setPath] = useState<string[]>([]);
  const [data, setData] = useState<
    RestEndpointMethodTypes['repos']['getContent']['response']
  >();

  const fetchGithub = useCallback(async () => {
    const octokit = new Octokit({
      auth: config.github.accessToken,
    });

    const response = await octokit.rest.repos.getContent({
      repo: 'codethrough-web',
      owner: 'msquitieri',
      path: path.join('/'),
    });

    console.log('response:', response.data);

    setData(response);
  }, [path]);

  const onSave = (pathToSave: string, folderContents: FolderContents[]) => {
    const newItem: CodethroughFolderItem = {
      ...item,
      path: pathToSave,
      folderContents,
    };

    dispatch(updateItem(newItem));
  };

  useEffect(() => {
    fetchGithub();
  }, [fetchGithub, path]);

  return (
    <div className='m-5 text-white'>
      {!data && (
        <div className='flex flex-row justify-center'>
          <PrimaryButton onClick={fetchGithub}>
            <p>Connect with Github</p>
          </PrimaryButton>
        </div>
      )}

      {data && (
        <div>
          <div className='mb-5'>
            <EditCodethroughTextItemRowContent item={item} />
          </div>

          <FolderContent
            path={path}
            // @ts-ignore
            contents={data.data}
            onPathChange={setPath}
            onSave={onSave}
          />
        </div>
      )}
    </div>
  );
};

export default EditCodethroughFolderItemRowContent;
