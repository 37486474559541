import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, CodethroughItem, CodethroughItemType } from '../types';
import {
  getIconForItemType,
  isFileCodethroughItem,
  isFolderCodethroughItem,
  isTextCodethroughItem,
} from '../helpers/CodethroughItemHelper';
import { removeItem } from '../slices/CodethroughSlice';
import EditCodethroughTextItemRowContent from './items/edit/EditCodethroughTextItemRowContent';
import EditCodethroughFolderItemRowContent from './items/edit/EditCodethroughFolderItemRowContent';
import EditCodethroughFileItemRowContent from './items/edit/EditCodethroughFileItemRowContent';

interface CodethroughItemProps {
  item: CodethroughItem;
}

const EditCodethroughItemRow: React.FC<CodethroughItemProps> = ({ item }) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className='flex flex-col w-full border'>
      <div className='flex flex-row justify-between'>
        <div>
          <button
            className='px-5 py-2 text-white bg-red-500 focus:outline-none'
            onClick={() => dispatch(removeItem(item))}
          >
            X
          </button>
        </div>
        <div className='flex flex-row items-center'>
          <span className='text-white'>{getIconForItemType(item.type)}</span>
          <div className='px-5 py-2 rounded text-white'>{item.type}</div>
        </div>
      </div>

      {isTextCodethroughItem(item) && (
        <div className='m-5'>
          <EditCodethroughTextItemRowContent item={item} />
        </div>
      )}
      {isFolderCodethroughItem(item) && (
        <EditCodethroughFolderItemRowContent item={item} />
      )}
      {isFileCodethroughItem(item) && (
        <EditCodethroughFileItemRowContent item={item} />
      )}
    </div>
  );
};

export default EditCodethroughItemRow;
