import React from 'react';
import PrimaryButton from './PrimaryButton';

interface ActionBarItemProps {
  title: string;
  icon: React.ReactElement;
  onClick(): void;
}

const ActionBarItem: React.FC<ActionBarItemProps> = ({
  title,
  icon,
  onClick,
}) => {
  return (
    <PrimaryButton onClick={onClick}>
      <span className='mr-1'>{icon}</span>
      <p>{title}</p>
    </PrimaryButton>
  );
};

export default ActionBarItem;
