import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CodethroughItem, CodethroughSliceState } from '../types';

const initialState: CodethroughSliceState = {
  title: 'Name goes here...',
  items: [],
};

const CodethroughSlice = createSlice({
  name: 'codethrough',
  initialState,
  reducers: {
    setTitle(state, action: PayloadAction<string>) {
      state.title = action.payload;
    },

    addItem(state, action: PayloadAction<CodethroughItem>) {
      state.items.push(action.payload);
    },

    removeItem(state, action: PayloadAction<CodethroughItem>) {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },

    updateItem(state, action: PayloadAction<CodethroughItem>) {
      state.items = state.items.map((item) =>
        item.id === action.payload.id ? { ...item, ...action.payload } : item,
      );
    },
  },
});

export const {
  addItem,
  removeItem,
  updateItem,
  setTitle,
} = CodethroughSlice.actions;

export default CodethroughSlice.reducer;
