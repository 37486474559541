import { Octokit } from 'octokit';
import React, { useCallback, useEffect, useState } from 'react';
import { RestEndpointMethodTypes } from '@octokit/plugin-rest-endpoint-methods/dist-types/generated/parameters-and-response-types';
import { useDispatch } from 'react-redux';
import { CodethroughFileItem, FileInfo } from '../../../types';
import PrimaryButton from '../../PrimaryButton';
import config from '../../../config';
import { updateItem } from '../../../slices/CodethroughSlice';
import FolderContent from '../folder/FolderContent';
import FileContent from '../file/FileContent';
import EditCodethroughTextItemRowContent from './EditCodethroughTextItemRowContent';

interface CodethroughFileItemProps {
  item: CodethroughFileItem;
}

function b64_to_utf8(str: string): string {
  return decodeURIComponent(escape(window.atob(str)));
}

const EditCodethroughFileItemRowContent: React.FC<CodethroughFileItemProps> = ({
  item,
}) => {
  const dispatch = useDispatch();
  const [fileInfo, setFileInfo] = useState<FileInfo>();
  const [path, setPath] = useState<string[]>([]);
  const [data, setData] = useState<
    RestEndpointMethodTypes['repos']['getContent']['response']
  >();
  const [fileName, setFileName] = useState<string>();

  const fetchGithub = useCallback(async () => {
    const octokit = new Octokit({
      auth: config.github.accessToken,
    });

    const response = await octokit.rest.repos.getContent({
      repo: 'codethrough-web',
      owner: 'msquitieri',
      path: path.join('/'),
    });

    console.log('response:', response.data);

    setData(response);
  }, [path]);

  const onSave = (fileInfo: FileInfo) => {
    const newItem: CodethroughFileItem = {
      ...item,
      path: fileInfo.path,
      fileName: fileInfo.name,
      fileContents: fileInfo.contents,
    };

    dispatch(updateItem(newItem));
  };

  useEffect(() => {
    fetchGithub();
  }, [fetchGithub, path]);

  useEffect(() => {
    const fetchFile = async () => {
      if (fileName) {
        const octokit = new Octokit({
          auth: config.github.accessToken,
        });

        const response = await octokit.rest.repos.getContent({
          repo: 'codethrough-web',
          owner: 'msquitieri',
          path: `${path.join('/')}/${fileName}`,
        });

        console.log('file response:', response);

        const info: FileInfo = {
          // @ts-ignore
          contents: b64_to_utf8(response.data.content),
          // @ts-ignore
          name: response.data.name,
          path: path.join('/'),
        };

        setFileInfo(info);
      }
    };

    fetchFile();
  }, [fileName]);

  return (
    <div className='m-5 text-white'>
      {!data && (
        <div className='flex flex-row justify-center'>
          <PrimaryButton onClick={fetchGithub}>
            <p>Connect with Github</p>
          </PrimaryButton>
        </div>
      )}

      {data && (
        <div>
          <div className='mb-5'>
            <EditCodethroughTextItemRowContent item={item} />
          </div>

          {!fileName && (
            <FolderContent
              path={path}
              // @ts-ignore
              contents={data.data}
              onPathChange={setPath}
              onFileClick={setFileName}
            />
          )}

          {fileInfo && (
            <FileContent
              fileInfo={fileInfo}
              onClear={() => {
                setFileInfo(undefined);
                setFileName(undefined);
              }}
              onSave={() => onSave(fileInfo)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default EditCodethroughFileItemRowContent;
