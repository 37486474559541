import React from 'react';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import { FolderContents } from '../../../types';

interface FolderRowProps {
  folder: FolderContents;
  onClick?(): void;
}

const FolderRow: React.FC<FolderRowProps> = ({ folder, onClick }) => {
  return (
    <button
      onClick={onClick}
      className='flex flex-row py-1 hover:bg-gray-300 hover:text-black'
    >
      <div className='ml-2 mr-5'>
        <FolderOpenIcon />
      </div>

      <span>{folder.name}</span>
    </button>
  );
};

export default FolderRow;
