import React from 'react';
import { CodethroughFileItem, FileInfo } from '../../../types';
import FileContent from '../file/FileContent';
import ViewCodethroughTextItemRowContent from './ViewCodethroughTextItemRowContent';

interface ViewCodethroughFileItemRowContentProps {
  item: CodethroughFileItem;
}

const ViewCodethroughFileItemRowContent: React.FC<ViewCodethroughFileItemRowContentProps> = ({
  item,
}) => {
  const fileInfo: FileInfo = {
    contents: item.fileContents,
    name: item.fileName,
    path: item.path,
  };

  return (
    <div>
      <div className='mb-5'>
        <ViewCodethroughTextItemRowContent item={item} />
      </div>

      <FileContent fileInfo={fileInfo} />
    </div>
  );
};

export default ViewCodethroughFileItemRowContent;
