import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, CodethroughTextItem } from '../../../types';
import { updateItem } from '../../../slices/CodethroughSlice';
import ContentEditableParagraph from '../../ContentEditableParagraph';

interface CodethroughTextItemProps {
  item: CodethroughTextItem;
}

const EditCodethroughTextItemRowContent: React.FC<CodethroughTextItemProps> = ({
  item,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className='text-white'>
      <ContentEditableParagraph
        content={item.content}
        // @ts-ignore
        setContent={(content) => dispatch(updateItem({ ...item, content }))}
      />
    </div>
  );
};

export default EditCodethroughTextItemRowContent;
