import React from 'react';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import PrimaryButton from '../../PrimaryButton';
import { FolderContents } from '../../../types';
import FolderRow from './FolderRow';
import FileRow from './FileRow';

interface FolderContentProps {
  path: string[];
  contents: FolderContents[];
  onPathChange?(newPath: string[]): void;
  onSave?(path: string, folderContents: FolderContents[]): void;
  onFileClick?(name: string): void;
}

const FolderContent: React.FC<FolderContentProps> = ({
  path,
  contents,
  onPathChange,
  onSave,
  onFileClick,
}) => {
  return (
    <div className='flex flex-col bg-warmGray-500 rounded-t'>
      <div className='flex flex-row justify-between items-center px-5 py-2'>
        <div className='flex flex-row text-lg text-white'>
          <FolderOpenIcon />
          <p className='ml-5'>/{path.join('/')}</p>
        </div>
        {onPathChange && (
          <div className='flex flex-row space-x-2'>
            <PrimaryButton
              onClick={() => {
                const newPath = [...path];
                newPath.pop();

                onPathChange(newPath);
              }}
            >
              Go Back
            </PrimaryButton>

            {onSave && (
              <PrimaryButton onClick={() => onSave(path.join('/'), contents)}>
                Save
              </PrimaryButton>
            )}
          </div>
        )}
      </div>

      <div className='bg-warmGray-800'>
        <div className='flex flex-col pl-5 text-white'>
          {contents.map((fileOrFolder: FolderContents) =>
            fileOrFolder.type === 'dir' ? (
              <FolderRow
                folder={fileOrFolder}
                onClick={() => {
                  const newPath = [...path, fileOrFolder.name];
                  if (onPathChange) {
                    onPathChange(newPath);
                  }
                }}
              />
            ) : (
              <FileRow file={fileOrFolder} onClick={onFileClick} />
            ),
          )}
        </div>
      </div>
    </div>
  );
};

export default FolderContent;
