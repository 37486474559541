import React from 'react';
import { CodethroughItem } from '../types';
import {
  isFileCodethroughItem,
  isFolderCodethroughItem,
  isTextCodethroughItem,
} from '../helpers/CodethroughItemHelper';
import ViewCodethroughTextItemRowContent from './items/view/ViewCodethroughTextItemRowContent';
import ViewCodethroughFileItemRowContent from './items/view/ViewCodethroughFileItemRowContent';
import StepNumber from './StepNumber';
import ViewCodethroughFolderItemRowContent from './items/view/ViewCodethroughFolderItemRowContent';

interface ViewCodethroughItemRowProps {
  item: CodethroughItem;
  stepNumber: number;
}

const ViewCodethroughItemRow: React.FC<ViewCodethroughItemRowProps> = ({
  item,
  stepNumber,
}) => {
  return (
    <div className='flex flex-col w-full border-b border-gray-700 py-5'>
      <div className='flex flex-row ml-auto'>
        <StepNumber number={stepNumber} />
      </div>

      {isTextCodethroughItem(item) && (
        <div className='m-5'>
          <ViewCodethroughTextItemRowContent item={item} />
        </div>
      )}
      {isFolderCodethroughItem(item) && (
        <ViewCodethroughFolderItemRowContent item={item} />
      )}
      {isFileCodethroughItem(item) && (
        <ViewCodethroughFileItemRowContent item={item} />
      )}
    </div>
  );
};

export default ViewCodethroughItemRow;
