import React from 'react';

interface StepNumberProps {
  number: number;
}

const StepNumber: React.FC<StepNumberProps> = ({ number }) => {
  return <span className='bg-white rounded-full px-3 py-1'>{number}</span>;
};

export default StepNumber;
