import React from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import PrimaryButton from '../components/PrimaryButton';
import { RootState } from '../types';
import ViewCodethroughItemRow from '../components/ViewCodethroughItemRow';

interface ViewCodethroughContainerProps extends RouteComponentProps {}

const ViewCodethroughContainer: React.FC<ViewCodethroughContainerProps> = ({
  history,
}) => {
  const { codethrough } = useSelector((state: RootState) => state);

  return (
    <div className='container mx-auto py-5'>
      <div className='flex flex-row justify-between text-white text-4xl my-5'>
        <p>{codethrough.title}</p>
        <div className='text-lg'>
          <PrimaryButton onClick={() => history.push('/')}>Edit</PrimaryButton>
        </div>
      </div>

      <div className='flex flex-col space-y-3 mt-5'>
        {codethrough.items.map((item, index) => (
          <ViewCodethroughItemRow
            key={item.id}
            item={item}
            stepNumber={index + 1}
          />
        ))}
      </div>
    </div>
  );
};

export default ViewCodethroughContainer;
