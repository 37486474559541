import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch, CodethroughItemType } from '../types';
import { addItem } from '../slices/CodethroughSlice';
import { getIconForItemType } from '../helpers/CodethroughItemHelper';
import ActionBarItem from './ActionBarItem';

interface ActionBarProps {}

const ActionBar: React.FC<ActionBarProps> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const types = [
    CodethroughItemType.TEXT,
    CodethroughItemType.FILE,
    CodethroughItemType.FOLDER,
  ];

  return (
    <div className='flex flex-row space-x-4 bg-gray-900 px-2 py-3 rounded'>
      <p className='text-white self-center rounded py-2 px-5'>Add:</p>

      {types.map((type) => (
        <ActionBarItem
          key={type}
          title={type}
          icon={getIconForItemType(type)}
          onClick={() => {
            dispatch(
              addItem({
                id: Math.random().toString(),
                type,
              }),
            );
          }}
        />
      ))}
    </div>
  );
};

export default ActionBar;
